import { Injectable } from "@angular/core";
import { ApiV3Service } from "../../api-v3.service";
import { StateService } from "@uirouter/core";
import { BehaviorSubject, map, Observable, switchMap } from "rxjs";
import { QueryResource } from "core-app/features/hal/resources/query-resource";
import { HookService } from "core-app/features/plugins/hook-service";
import { WidgetActivityComponent } from "core-app/shared/components/grids/widgets/activity/activity.component";
import { WidgetCustomTextComponent } from "core-app/shared/components/grids/widgets/custom-text/custom-text.component";
import { WidgetNewsComponent } from "core-app/shared/components/grids/widgets/news/news.component";
import { WidgetProjectFavoritesComponent } from "core-app/shared/components/grids/widgets/project-favorites/widget-project-favorites.component";
import { WidgetWpCalendarComponent } from "core-app/shared/components/grids/widgets/wp-calendar/wp-calendar.component";
import { WidgetWpOverviewComponent } from "core-app/shared/components/grids/widgets/wp-overview/wp-overview.component";
import { WidgetWpTableQuerySpaceComponent } from "core-app/shared/components/grids/widgets/wp-table/wp-table-qs.component";
import { WidgetWpTeamComponent } from "core-app/shared/components/grids/widgets/wp-team/wp-team.component";
import { assignedProps, accountableProps, createdProps, watchedProps } from "core-app/shared/components/grids/openproject-grids.module";
import { WidgetProjectsListComponent } from "core-app/shared/components/grids/widgets/projects-list/projects-list.component";

@Injectable()
export class ConfigQueryService {

    public isManager$ = new BehaviorSubject<boolean>(false);

    constructor(
        private apiV3Service: ApiV3Service,
        private hookService: HookService,
        private state: StateService) {
    }

    public createQuery(props: { [key: string]: any }): Observable<QueryResource> {
        const projectIdentifier = this.state.params.projectPath as string;
        const initializationProps = props;
        const queryProps = {
            pageSize: 0,
            ...initializationProps,
        };
        return this
            .apiV3Service
            .queries
            .form
            .loadWithParams(
                queryProps,
                undefined,
                projectIdentifier,
                this.queryCreationParams(),
            )
            .pipe(
                switchMap(([form, query]) => this
                    .apiV3Service
                    .queries
                    .post(query, form)
                    .pipe(
                        map((resource: QueryResource) => {
                            return resource;
                        }),
                    ),
                ),
            );
    }

    protected queryCreationParams() {
        return {
            hidden: false,
            public: false,
        };
    }

    public setIsManager(value: boolean) {
        console.log('setIsManager', value);
        this.isManager$.next(value);
    }

    public getIsManager$(): Observable<boolean> {
        return this.isManager$.asObservable();
    }

    public destroyIsManager$(): void {
        this.isManager$.unsubscribe;
    }

    private get baseWidgets() {
        return [
            {
                identifier: 'work_packages_assigned',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Переданные мне пакеты работ',
                properties: {
                    queryProps: assignedProps(),
                    name: 'Переданные мне пакеты работ',
                },
            },
            {
                identifier: 'work_packages_accountable',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Пакеты работ, в которых я подотчетен',
                properties: {
                    queryProps: accountableProps(),
                    name: 'Пакеты работ, в которых я подотчетен',
                },
            },
            {
                identifier: 'work_packages_created',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Пакеты работ, созданные мной',
                properties: {
                    queryProps: createdProps(),
                    name: 'Пакеты работ, созданные мной',
                },
            },
            {
                identifier: 'work_packages_watched',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Пакеты работ, просмотренные мной',
                properties: {
                    queryProps: watchedProps(),
                    name: 'Пакеты работ, просмотренные мной',
                },
            },
            {
                identifier: 'work_packages_table',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Таблица пакетов работ',
                properties: {
                    name: 'Таблица пакетов работ',
                },
            },
            {
                identifier: 'work_packages_calendar',
                component: WidgetWpCalendarComponent,
                title: 'Календарь',
                properties: {
                    name: 'Календарь',
                },
            },
            {
                identifier: 'work_packages_overview',
                component: WidgetWpOverviewComponent,
                title: 'Обзор пакетов работ',
                properties: {
                    name: 'Обзор пакетов работ',
                },
            },
            {
                identifier: 'news',
                component: WidgetNewsComponent,
                title: 'Новости',
                properties: {
                    name: 'Новости',
                },
            },
            {
                identifier: 'custom_text',
                component: WidgetCustomTextComponent,
                title: 'Пользовательский текст',
                properties: {
                    name: 'Пользовательский текст',
                    text: {
                        raw: '',
                    },
                },
            },
            {
                identifier: 'project_favorites',
                component: WidgetProjectFavoritesComponent,
                title: 'Избранные проекты',
                properties: {
                    name: 'Избранные проекты',
                },
            },
            {
                identifier: 'team',
                component: WidgetWpTeamComponent,
                title: "Команда",
                properties: {
                    name: "Команда",
                },
            },
            {
                identifier: 'event_activity',
                component: WidgetActivityComponent,
                title: "Деятельность по проектам",
                properties: {
                    name: "Деятельность по проектам",
                },
            },
        ];
    }

    private get mainWidgets() {
        return [
            {
                identifier: 'work_packages_table',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Проекты (Главная)',
                properties: {
                    name: 'Проекты (Главная)',
                },
            },
            {
                identifier: 'work_packages_calendar',
                component: WidgetWpCalendarComponent,
                title: 'Календарь',
                properties: {
                    name: 'Календарь',
                },
            },
            {
                identifier: 'news',
                component: WidgetNewsComponent,
                title: 'Новости',
                properties: {
                    name: 'Новости',
                },
            },
            {
                identifier: 'custom_text',
                component: WidgetCustomTextComponent,
                title: 'Пользовательский текст',
                properties: {
                    name: 'Пользовательский текст',
                    text: {
                        raw: '',
                    },
                },
            },
        ];
    }

    private joinWidgets(confProps: { [key: string]: any }) {
        return [
            {
                identifier: 'work_packages_table',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Совещания и поручения',
                properties: {
                    queryProps: confProps,
                    name: 'Совещания и поручения',
                },
            },
        ];
    }

    private managerWidgets(suofProps: { [key: string]: any }) {
        return [
            {
                identifier: 'projects_list',
                component: WidgetProjectsListComponent,
                title: 'Список проектов',
                properties: {
                    name: 'Список проектов',
                },
            },
            {
                identifier: 'work_packages_table',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Проекты в работе по менеджерам СУОФ',
                properties: {
                    queryProps: suofProps,
                    name: 'Проекты в работе по менеджерам СУОФ',
                },
            },
        ];
    }


    private workerWidgets(stageProps: { [key: string]: any }, extendProjectsProps: { [key: string]: any }) {
        return [
            {
                identifier: 'work_packages_table',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Основные вехи по проектам',
                properties: {
                    queryProps: stageProps,
                    name: 'Основные вехи по проектам',
                },
            },
            {
                identifier: 'work_packages_table',
                component: WidgetWpTableQuerySpaceComponent,
                title: 'Расширенный список проектов',
                properties: {
                    queryProps: extendProjectsProps,
                    name: 'Расширенный список проектов',
                },
            },
        ];
    }

    public registedWidgets(isManager: boolean,
        conf: { [key: string]: any },
        suof: { [key: string]: any },
        stage?: { [key: string]: any },
        extendProjects?: { [key: string]: any }): boolean {

        const base = [...this.baseWidgets, ...this.joinWidgets(conf)];       
        const result = isManager ? [...base, ...this.managerWidgets(suof)] : [...base, ...this.workerWidgets(stage!, extendProjects!)];
        console.log('result', result, isManager);
        this.hookService.register(isManager ? 'gridWidgetsManager' : 'gridWidgetsWorker', () => {
            return result;
        });
        return true;
    }

    public registedMainWidgets(): boolean {

        this.hookService.register('gridWidgetsMain', () => {
            return this.mainWidgets;
        });
        return true;
    }
}