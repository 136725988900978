import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'new-custom-expansion-panel',
    templateUrl: "./new-expansion-panel.component.html",
    styleUrls: ['./new-expansion-panel.component.sass']
})

export class NewExpansionPanelComponent {
    @Input() title: string = '';
    
    @Input() expanded: boolean = false;

    @Output() expandedChange = new EventEmitter<boolean>();

    toggle() {
        this.expanded = !this.expanded;
        this.expandedChange.emit(this.expanded);
    }
}