// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, signal, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { IHALCollection } from "core-app/core/apiv3/types/hal-collection.type";
import { I18nService } from "core-app/core/i18n/i18n.service";
import { GroupActivity, GroupedItem, GroupEvents, IActivity, IRequestActivity, Project } from "core-app/core/state/activity/activity.model";
import { ActivityResourceService } from "core-app/core/state/activity/activity.service";
import { CollectionResource } from "core-app/features/hal/resources/collection-resource";
import { AbstractWidgetComponent } from "core-app/shared/components/grids/widgets/abstract-widget.component";
import moment from "moment";
import { Subscription } from "rxjs";


@Component({
  templateUrl: "./activity.component.html",
  styleUrls: ['./activity.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetActivityComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  constructor(
    protected readonly i18n: I18nService,
    protected readonly injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly activityService: ActivityResourceService,
  ) {
    super(i18n, injector);
  }

  public activityHAL: IHALCollection<IActivity>;
  protected activityResurses: CollectionResource<IRequestActivity>
  protected activity: IRequestActivity;
  private subActivity: Subscription;
  
  readonly panelOpenState = signal(false);

  panelExpanded = false;

  groupedItems: GroupEvents[] = [];

  groups: GroupActivity[] = [];

  result: GroupedItem[] = [];

  @ViewChild('accordion', { static: true }) Accordion: MatAccordion

  ngOnInit(): void {
    this.subActivity = this.activityService.list({})
      .subscribe((activity) => {
        this.activityResurses = activity;
        this.activity = this.activityResurses.$source as IRequestActivity;

        for (const key in this.activity) {

          if (key.includes('=>')) {
            const keyNew = this.activity[key][0].project.identifier;
            const projectName = this.activity[key][0].project.name;

            for (const item of this.activity[key]) {
              item.datetime = moment(item.event_datetime).format('DD.MM.YYYY HH:mm');
              item.event_datetime = moment(item.event_datetime).format('DD.MM.YYYY');
            }

            this.groupedItems = this.groupBy(this.activity[key], 'event_datetime');
            this.groups.push({ project: { key: keyNew, name: projectName }, items: this.groupedItems });
            console.log("this.resultGroups", this.groupedItems, this.groups, this.result);
          }
        }
        this.result = this.groupByDateAndProject(this.groups);
        console.log("result", this.result);
        this.cdr.detectChanges();
      });
  }

  groupBy(array: any[], property: string): { date: string; events: IActivity[] }[] {
    return Object.values(
      array.reduce((res, obj) => {
        const key = obj[property]; // Use toDateString for grouping
        if (!res[key]) {
          res[key] = { date: obj[property], events: [] };
        }
        res[key].events.push(obj);
        return res;
      }, {})
    );
  }

  groupByDateAndProject(array: { project: Project; items: GroupEvents[] }[]): GroupedItem[] {
    const groupedByDate: { [dateKey: string]: GroupedItem } = {};

    array.forEach((group) => {
      group.items.forEach((eventItem) => {
        const dateKey = eventItem.date;

        if (!groupedByDate[dateKey]) {
          groupedByDate[dateKey] = { date: dateKey, projects: [] };
        }

        const projectIndex = groupedByDate[dateKey].projects.findIndex(
          (projectGroup) => projectGroup.project.key === group.project.key
        );

        if (projectIndex === -1) {
          groupedByDate[dateKey].projects.push({
            project: group.project,
            events: eventItem.events
          });
        } else {
          groupedByDate[dateKey].projects[projectIndex].events.push(
            ...eventItem.events
          );
        }
      });
    });
    return Object.values(groupedByDate);
  }

  ngOnDestroy(): void {
    this.subActivity.unsubscribe();
  }

  beforePanelClosed(panel: any) {
    panel.isExpanded = false;
  }

  beforePanelOpened(panel: any) {
    panel.isExpanded = true;
  }

  afterPanelClosed() {
    //console.log("Panel closed!");
  }
  afterPanelOpened() {
    //console.log("Panel opened!");
  }

  closeAllPanels() {
    this.Accordion.closeAll();
  }
  openAllPanels() {
    this.Accordion.openAll();
  }

  onPanelExpandedChange(expanded: boolean) {
    //this.panelExpanded = expanded;
  }
}
