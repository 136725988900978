<widget-header [name]="widgetName" (onRenamed)="renameWidget($event)">
  <widget-menu slot="menu" [resource]="resource"> </widget-menu>
</widget-header>

<div class="generic-container">
  <div *ngFor="let res of result">
    <new-custom-expansion-panel [title]="res.date" [expanded]="panelExpanded"
      (expandedChange)="onPanelExpandedChange($event)">
      <div>
        <mat-accordion [multi]="true" #accordion="matAccordion">
          <mat-expansion-panel [disabled]="area.isDisabled" [expanded]="area .isExpanded"
            (opened)="beforePanelOpened(area)" (closed)="beforePanelClosed(area)"
            (afterCollapse)="afterPanelClosed($event)" (afterExpand)="afterPanelOpened($event)"
            *ngFor="let area of res.projects" #mapanel="matExpansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="area-title" href="/projects/{{area.project.key}}">{{area.project.name}}</a>
              </mat-panel-title>
            </mat-expansion-panel-header>            
            <div>
              <div class="card" *ngFor="let card of area.events">
                <div class="card-header">
                  <a class="card-header-title" href="{{card.event_path}}">
                    {{card.event_title}}
                  </a>
                </div>
                <div class="card-row">
                  <p>{{card.event_name}}</p>
                  <div class="card-row-user">
                    <p class="card-row-user-text">автор:</p>
                    <a class="card-row-user-author"
                      href="/users/{{card.event_author.id}}">{{card.event_author.firstname}} {{card.event_author.lastname}}</a>
                    <p class="card-row-user-text">{{card.datetime}}</p>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </new-custom-expansion-panel>
  </div>
  <div class="link">
    <a class="link-title" href="/activity">Перейти в модуль "Деятельность"</a>
  </div>
</div>