import { GridGap } from "../areas/grid-gap";
import { GridWidgetArea } from "../areas/grid-widget-area";
import { AbstractWidgetComponent } from "../widgets/abstract-widget.component";
import { GridAddWidgetService } from "./page-service/add-widget.service";
import { GridMoveService } from "./page-service/move.service";
import { GridAreaService } from "./page-service/area.service";
import { GridDragAndDropService } from "./page-service/drag-and-drop.service";
import { GridResizeService } from "./page-service/resize.service";
import { GridRemoveWidgetService } from "./page-service/remove-widget.service";

export interface WidgetRegistration {
  identifier: string;
  title: string;
  component: { new(...args: any[]): AbstractWidgetComponent };
  properties?: Record<string, unknown>;
}

export const GRID_PROVIDERS = [
  GridAreaService,
  GridMoveService,
  GridDragAndDropService,
  GridResizeService,
  GridAddWidgetService,
  GridRemoveWidgetService,
];

export interface WidgetConfig {
  area: GridWidgetArea,
  gap?: GridGap
}

export interface MainRequest {
  grid: MainGrid,
  message: string
}

export interface MainGrid {
  id: number,
  row_count: number
  column_count: number,
  user_id: number,
  created_at: string,
  updated_at: string,
  project_id: number,
  options: { widgets?: string }
}

export interface MainGridDTO {
  row_count: number,
  column_count: number,
  user_id?: number,
  options: { widgets?: string }
}

export interface WidgetSave {
  _type: string,
  identifier: string,
  startRow: number,
  endRow: number,
  startColumn: number,
  endColumn: number,
  options: { [key: string]: unknown };
}
