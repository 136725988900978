<widget-header
    [name]="widgetName"
    (onRenamed)="renameWidget($event)">

  <widget-menu
    slot="menu"
    [resource]="resource">
  </widget-menu>
</widget-header>

<div>
  <span class="label-projects">Проекты:</span>
  <div>
    <select class="select" (change)="onOptionChange($event)">
      <option *ngFor="let project of projects" [value]="project.identifier">
        {{ project.name }}
      </option>
    </select>
  </div>

  <table class="generic-table">
    <colgroup>
      <col opHighlightCol />
      <col opHighlightCol />
      <col opHighlightCol />
    </colgroup>
    <thead>
      <tr>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Имя</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Исполнители</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Создано</span>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="row" *ngFor="let team of teams" (click)="selectTeam(team)" [class.selected]="team === selectedTeam">
        <td class="name">
          <a href="/projects/{{ selectedProject }}/team_planners/{{ team.id }}" [textContent]="team.name"></a>
        </td>
        <td class="users">
          <span [textContent]="team.users"></span>
        </td>
        <td class="created_at">
          <span [textContent]="team.created_at"></span>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="showTimeline" class="team-wrapper">
  <new-ts [items]="items" [periods]="periods" [sections]="sections" [events]="events" [showBusinessDayOnly]="false"
    [allowDragging]="false"></new-ts>
  <div class="link">
    <a class="create" href="/projects/{{ selectedProject }}/team_planners/new">Перейти в модуль "Командный планировщик"</a>
  </div>
</div>

<div *ngIf="!showTimeline && selectedProject" class="team-wrapper">
  <a class="create" href="/projects/{{ selectedProject }}/team_planners/new">Создать новый планировщик</a>
</div>